import React, { useEffect, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import bannerImg from "../../assets/images/hyfen/intangible-img.png";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel, AccordionItemButton } from "react-accessible-accordion";

import { useLocation } from "@reach/router";

const FrameworkPage = () => {
  const [hasMounted, setHasMounted] = useState(false);
  const location = useLocation().hash.substring(1) || "";

  const scroll = (id) => {
    const section = document.querySelector(`#${id}`);
    section.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);
  useEffect(() => {
    if (location !== "") {
      scroll(location);
    }
  }, [hasMounted]);

  return (
    <div className="framework-page">
      <section className="section1 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h1 className="heading-2">Here's how we define our 8 intangibles</h1>
            </div>
            <div className="col-12 col-md-6">
              <p className="text-4">
                Our team used its decades of experience and multi-industry and academic expertise to distil and define the full scope of intangible assets into
                8 measurable, scalable dimensions. Learn more about the 8 dimensions below.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section2">
        <div className="container pb-100">
          <h1 className="heading-2 mb-5">
            The Hyfen Eight<sup className="registered">&reg;</sup>
          </h1>
          <div className="framework-accordion">
            <Accordion allowZeroExpanded preExpanded={[location || "OperatingModel"]}>
              <AccordionItem uuid="OperatingModel">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="OperatingModel">
                      <span></span>Operating Model
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">How you translate assets into value</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Your operating model turns other intangibles into value creators and includes business processes, organisational structures, goals and
                        incentives, enabling capabilities and technologies that drive automation and asset utilisation. Like a fine motor, to derive maximum
                        value your operating model has to be designed integrally and regularly tuned for efficiency, scalability and effectiveness.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="Ecosystem">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="Ecosystem">
                      <span></span>Ecosystem
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">The strength of your relationships</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Your ecosystem is your connection with the environment in which your organisation operates. This includes the strength and
                        leveragability of your relationships with external parties, governments, regulatory bodies, suppliers, customers and more. Healthy
                        ecosystem capital reduces costs, increases options, and improves flywheel capacity, resilience and scalability.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="Brand">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="Brand">
                      <span></span>Brand
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">The power of how people see you</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        A strong brand connects you with your target market. Great brands embody positive customer experiences, appealing on emotional and
                        rational levels. Brand assets aren't built by accident. Optimising your brand uplevels growth, guards against reputation risk, improves
                        the predictability and volume of cashflows, creates access to markets and builds adaptive potential into your strategy.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="Data">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="Data">
                      <span></span>Data
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">The future-essential intangible</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Tiny companies have risen to become colossal global forces on the back of data alone. A product in its own right, strategically utilised
                        data can become the intelligent engine room of your organisation, driving decision effectiveness, product-market fit, market adoption
                        and operating efficiencies. With AI in play, you add a whole new dimension. Data is one of the most powerful future-proofing assets
                        there is.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="Knowhow">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="Knowhow">
                      <span></span>Knowhow
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">Your proprietary smarts</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Knowhow is one of the keys to unlocking sustainable recurring revenue. It includes your trade secrets, IP, patents, copyright and other
                        proprietary assets. But knowhow is vulnerable if it’s stuck in people’s heads or unprotected. Smart legal and people strategies can help
                        here, as can careful operating model design.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="Rights">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="Rights">
                      <span></span>Rights
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">Your access to opportunities</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Rights cover your entitlement to access an asset or opportunity now or in the future. They include regulatory approvals, licences,
                        leases, decision rights, contracts and options to buy or access an asset or benefit later. Privileged, restricted or exclusive rights
                        generate superior financial returns, mediated by market relevance and sustainable longevity.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="People">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="People">
                      <span></span>People
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">The cliché is true: your most important asset</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        People is talent and how people work together. Your people asset is your complete workforce: its agility, engagement, collaborative
                        potential, productivity, resilience and learning capacity. The value-capture from a strong people asset is incomparable. But be warned:
                        single person risk, exit risk, poor culture, or lack of access to the right people can cripple the value of all your other intangibles
                        combined.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>

              <AccordionItem uuid="BusinessModel">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div id="BusinessModel">
                      <span></span>Business Model
                    </div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="row py-5">
                    <div className="col-12 col-md-6 pb-md-0 pb-5">
                      <p className="accordion-txt-left">How you create value (for you and others)</p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p className="accordion-txt-right">
                        Your business model is the way your organisation creates, delivers and captures value. For scale-ups, it articulates your growth pathway
                        and flywheel capacity. For mature businesses, it defines how you capture value from an established value chain or network. The most
                        powerful business models can redefine the market itself. All business models have shelf lives: adaptability and optionality strengthen
                        this asset.
                      </p>
                    </div>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </section>
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-10">
              <p>
                Technology and AI are always evolving, and so is Hyfen8. We are constantly evaluating, refining and optimising the Hyfen8 platform. We know that
                change is the only constant, so we use it to our advantage - and yours.
              </p>
            </div>
          </div>
        </div>
      </section>
      <img className="d-block d-md-none" src={bannerImg} alt="bannerImg" />
    </div>
  );
};

export default FrameworkPage;
