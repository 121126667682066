import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import IntangiblesBanner from "../components/FrameworkIntangibles/IntangiblesBanner";
import FrameworkPage from "../components/FrameworkIntangibles/FrameworkPage";

const FrameworkIntangibles = () => {
  return (
    <Layout title={"Intangible frameworks"}>
      <Navbar />
      <IntangiblesBanner />
      <FrameworkPage />
      <Footer />
    </Layout>
  );
};

export default FrameworkIntangibles;
